/*
  Authors : NexHut (Wassim Dossani)
  Website : https://NexHut.com/
  App Name : Quickerz
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://NexHut.com/license
  Copyright © 2021-present NexHut.
*/
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Dashboard',
    main: [
      {
        state: 'dashboard',
        name: 'Home',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'stores',
        name: 'Merchant Stores',
        type: 'link',
        icon: 'ti-notepad'
      },
      {
        state: 'orders',
        name: 'Orders',
        type: 'link',
        icon: 'ti-shopping-cart'
      },
    ],
  },
  {
    label: 'Manage',
    main: [
      {
        state: 'users',
        name: 'Quickerz Customers',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'wholesale',
        name: 'Quickerz Wholesale Customers',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'drivers',
        name: 'Delivery Drivers',
        type: 'link',
        icon: 'ti-truck'
      },
    ],
  },
  {
    label: 'Inventory',
    main: [
      {
        state: 'category',
        name: 'Categories',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
      {
        state: 'products',
        name: 'Products',
        type: 'link',
        icon: 'ti-package'
      }  
    ],
  },
  {
    label: 'Analytics',
    main: [
      {
        state: 'stats',
        name: 'Store Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'driver-stats',
        name: 'Drivers Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'vehicle-logs',
        name: 'Vehicle Logs',
        type: 'link',
        icon: 'ti-car'
      }
    ],
  },
  {
    label: 'Marketing',
    main: [
      {
        state: 'notifications',
        name: 'Push Notification',
        type: 'link',
        icon: 'ti-bell'
      },
      {
        state: 'offers',
        name: 'Coupons',
        type: 'link',
        icon: 'ti-medall'
      },
      {
        state: 'manage-popup',
        name: 'Manage Popup',
        type: 'link',
        icon: 'ti-quote-right'
      },
      {
        state: 'banners',
        name: 'Banners',
        type: 'link',
        icon: 'ti-layout-list-large-image',
      },
      {
        state: 'blogs',
        name: 'Blogs',
        type: 'link',
        icon: 'ti-book',
      },
      {
        state: 'send-mail',
        name: 'Email Marketing',
        type: 'link',
        icon: 'ti-email'
      },
    ],
  },
  {
    label: 'Support',
    main: [
      {
        state: 'contacts',
        name: 'Live Chat',
        type: 'link',
        icon: 'ti-comments-smiley'
      },
      {
        state: 'emails',
        name: 'Ticket Support',
        type: 'link',
        icon: 'ti-email'
      },
    ],
  },
  {
    label: 'Assets',
    main: [
      {
        state: 'vehicles',
        name: 'Vehicles',
        type: 'link',
        icon: 'ti-car'
      }
    ]
  },
  {
    label: 'Settings',
    main: [
      {
        state: 'manage-app',
        name: 'Manage App',
        type: 'link',
        icon: 'ti-lock'
      },
      {
        state: 'app-settings',
        name: 'App Settings',
        type: 'link',
        icon: 'ti-desktop'
      },
      {
        state: 'general',
        name: 'General',
        type: 'link',
        icon: 'ti-settings'
      },
      {
        state: 'app-pages',
        name: 'App Pages',
        type: 'link',
        icon: 'ti-blackboard'
      },
      {
        state: 'payment',
        name: 'Payment Settings',
        type: 'link',
        icon: 'ti-money'
      },
      {
        state: 'administrantor',
        name: 'Administrator',
        type: 'link',
        icon: 'ti-id-badge'
      },
      {
        state: 'languages',
        name: 'Languages',
        type: 'link',
        icon: 'ti-world'
      },
      // {
      //   state: 'cities',
      //   name: 'Available Markets',
      //   type: 'link',
      //   icon: 'ti-map'
      // }
    ],
  },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
